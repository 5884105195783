import { createSlice } from "@reduxjs/toolkit";
import { logIn, logOut } from "../../actions/Auth/KeycloakAction";
import { getUserProfile, getUserRewards } from "../../actions/User/UserAction";

const SLICE_NAME = "user";

const storageToken = localStorage.getItem("talentwunder.token") ?? null;
const storageRefreshToken = localStorage.getItem("talentwunder.refresh.token") ?? null;

const initialState = {
    loading: true,
    id: null,
    token: storageToken || null,
    refreshToken: storageRefreshToken || null,
    formErrors: null,
    userError: false,
    userProfile: null,
    profilePicture: "",
    emailNotifications: false,
    userRewradsTotal: 0,
    lastName: "",
    firstName: "",
    language: "",
    userRewards: [],
};

export const userSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    reducers: {
        clearFormErrors(state) {
            state.formErrors = null;
        },
        setLanguage: (state, action) => {
            state.language = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(logIn.pending, (state) => {
                state.loading = true;
                state.userError = false;
            })
            .addCase(logIn.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.token = payload.access_token;
                state.refreshToken = payload.refresh_token;
                localStorage.setItem("talentwunder.token", payload.access_token);
                localStorage.setItem("talentwunder.refresh.token", payload.refresh_token);
            })
            .addCase(logIn.rejected, (state) => {
                state.loading = false;
                state.userError = true;
            })
            .addCase(logOut.pending, (state) => {
                state.loading = true;
            })
            .addCase(logOut.fulfilled, (state) => {
                state.loading = false;
                state.token = null;
                state.refreshToken = null;
                state.id = null;
                localStorage.removeItem("talentwunder.token");
                localStorage.removeItem("talentwunder.refresh.token");
                localStorage.removeItem("talentwunder.firstName");
                localStorage.removeItem("talentwunder.lastName");
                localStorage.removeItem("talentwunder.profilePicture");
                localStorage.removeItem("talentwunder.emailNotifications");
            })
            .addCase(getUserProfile.pending, (state) => {
                state.loading = true;
                state.userError = false;
            })
            .addCase(getUserProfile.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.id = payload.id;
                state.firstName = payload.firstName;
                state.lastName = payload.lastName;
                state.profilePicture = payload.profilePicture;
                state.emailNotifications = payload.emailNotifications;
                state.language = payload.language;

                localStorage.setItem("talentwunder.firstName", payload.firstName ?? " ");
                localStorage.setItem("talentwunder.lastName", payload.lastName ?? " ");
                localStorage.setItem("talentwunder.profilePicture", payload.profilePicture);
                localStorage.setItem("talentwunder.emailNotifications", payload.emailNotifications);
                localStorage.setItem("talentwunder.application.language", payload.language);

            })
            .addCase(getUserProfile.rejected, (state) => {
                state.loading = false;
                state.userError = true;
            })
            .addCase(getUserRewards.pending, (state) => {
                state.loading = true;
                state.userError = false;
            })
            .addCase(getUserRewards.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.userRewards = payload;                
                state.userRewradsTotal = payload
                    .filter(item => !item.claimed)
                    .reduce((total, currentValue) => total + currentValue?.credits, 0);
            })
            .addCase(getUserRewards.rejected, (state) => {
                state.loading = false;
                state.userError = true;
            })
    },
});

export const { setLanguage } = userSlice.actions;

export const {   
    clearFormErrors,
} = userSlice.actions;

const getAppState = (state) => state[SLICE_NAME];

export const userSelectors = {
    loading: (rootState) => {
        const appState = getAppState(rootState);
        return appState.loading;
    },
    userProfile: (rootState) => {
        const appState = getAppState(rootState);
        return {
            id: appState.id,
            firstName: appState.firstName,
            lastName: appState.lastName,
            profilePicture: appState.profilePicture,
            emailNotifications: appState.emailNotifications,
            language: appState.language,
        };
    },
    userRewards: (rootState) => {
        const appState = getAppState(rootState);
        return appState.userRewards ?? [];
    },
    userRewradsTotal: (rootState) => {
        const appState = getAppState(rootState);
        return appState.userRewradsTotal ?? 0;
    },
    userError: (rootState) => {
        const appState = getAppState(rootState);
        return appState.userError;
    },
    token: (rootState) => {
        const appState = getAppState(rootState);
        return appState.token;
    },
    refreshToken: (rootState) => {
        const appState = getAppState(rootState);
        return appState.refreshToken;
    },
    formErrors: (rootState) => {
        const appState = getAppState(rootState);
        return appState.formErrors;
    },
}
