import { createSlice } from "@reduxjs/toolkit";
import { getNotifications } from "../../actions/Notification/NotificationAction";

const SLICE_NAME = "notification";

const initialState = {
    loading: false,
    notifications: [],
    notificationError: false,
    unreadNotificationNum: 0,
};

export const notificationSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getNotifications.pending, (state) => {
                state.loading = true;
                state.notificationError = false;
            })
            .addCase(getNotifications.fulfilled, (state, { payload }) => {
                state.loading = false;
                const unreadNotificaitons = payload.filter((item) => {
                    return !item.read;
                });
                state.unreadNotificationNum = unreadNotificaitons.length;
                state.notifications = payload;
            })
            .addCase(getNotifications.rejected, (state) => {
                state.loading = false;
                state.notificationError = true;
            })
    },
});

const getNotificationState = (state) => state[SLICE_NAME];

export const notificationSelectors = {
    loading: (rootState) => {
        const notificationState = getNotificationState(rootState);
        return notificationState.loading;
    },
    notifications: (rootState) => {
        const notificationState = getNotificationState(rootState);
        return notificationState.notifications ?? [];
    },
    unreadNotificationNum: (rootState) => {
        const notificationState = getNotificationState(rootState);
        return notificationState.unreadNotificationNum;
    },
    notificationError: (rootState) => {
        const notificationState = getNotificationState(rootState);
        return notificationState.notificationError;
    },
};
