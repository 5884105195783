import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { getVersion, isLocalEnvironment } from "../src/utils/utils";
import countries from 'i18n-iso-countries';

import countriesEn from 'i18n-iso-countries/langs/en.json';
import countriesDe from 'i18n-iso-countries/langs/de.json';

function getJsonPath() {
  if (!isLocalEnvironment()) {
      return `/static/locales/{{lng}}/{{ns}}_${getVersion()}.json`;
  }
  return "/static/locales/{{lng}}/{{ns}}.json";
}

countries.registerLocale(countriesEn);
countries.registerLocale(countriesDe);

i18n.use(HttpApi)
    .use(initReactI18next)
    .init({
        supportedLngs: ["en-US", "de-DE"],
        lng: localStorage.getItem('talentwunder.application.language') ?? "de-DE",
        fallbackLng: "de-DE",
        debug: false,
        load: "unspecific",
        ns: ["translation"],
        defaultNS: "translation",
        interpolation: {
            escapeValue: false,
        },
        react: {
            useSuspense: false,
        },
        backend: {
            loadPath: getJsonPath(),
            allowMultiLoading: false,
        },
        detection: {
          order: ["querystring", "localStorage", "navigator", "htmlTag"],
          caches: ["localStorage"],
          lookupLocalStorage: "talentwunder.application.language",
          htmlTag: document.documentElement,
      },
    });

export default i18n;
