import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getOrganizationSettings = createAsyncThunk(
    "organization/settings",
    async (prefix) => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_BASE_URL}/organizations/settings`, {
            params: {
                prefix: prefix,
            }
        });

        return data;
    }
);
