import { createAsyncThunk } from "@reduxjs/toolkit";
import { getSectionsApi } from "../../services/api/Section/SectionService";

export const getSections = createAsyncThunk("sections", async (language) => {
    try {
        const { data } = await getSectionsApi(language);
        return data;
    } catch (error) {
        return error.response.data;
    }
});
