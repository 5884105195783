import { createSlice } from "@reduxjs/toolkit";
import { getReferrals } from "../../actions/Referral/ReferralAction";

const SLICE_NAME = "referral";

const initialState = {
    loading: true,
    referrals: [],
    referralError: false,
    modal: false,
    drawer: {
        type: "",
        isOpen: false
    },
};

export const referralSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getReferrals.pending, (state) => {
                state.loading = true;
                state.referralError = false;
            })
            .addCase(getReferrals.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.referrals = payload;
            })
            .addCase(getReferrals.rejected, (state) => {
                state.loading = false;
                state.referralError = true;
            });
    },
    reducers: {
        setModal: (state, action) => {
            state.modal = action.payload;
        },
        setDrawer: (state, action) => {
            state.drawer = action.payload;
        },
    }
});

const getReferralState = (state) => state[SLICE_NAME];

export const referralSelectors = {
    loading: (rootState) => {
        const referralState = getReferralState(rootState);
        return referralState.loading;
    },
    referrals: (rootState) => {
        const referralState = getReferralState(rootState);
        return referralState.referrals ?? [];
    },
    referralError: (rootState) => {
        const referralState = getReferralState(rootState);
        return referralState.referralError;
    },
};

export const { setModal, setDrawer } = referralSlice.actions;
