import axios from "axios";
import { stringify } from "qs";
import { store } from "../../core/Store";
import { addMessage } from "../../../slices/Notification/GlobalNotificationSlice";

export const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    paramsSerializer: { serialize: stringify }
});

axiosInstance.interceptors.request.use((config) => {
    const token = localStorage.getItem("talentwunder.token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
});

const notificationSource = "Axios";

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;
        const status = error.response ? error.response.status : null;

        if ((status === 401 || status === 403 ) && !originalRequest._retry) {
            originalRequest._retry = true;
            const serverUrl = process.env.REACT_APP_KEYCLOAK_URL;
            const realm = process.env.REACT_APP_KEYCLOAK_REALM;
            let qs = require('qs');
            const refreshToken = localStorage.getItem("talentwunder.refresh.token");

            try {
                const response = await axios.post(
                    `${serverUrl}/realms/${realm}/protocol/openid-connect/token`,
                    qs.stringify({
                        client_id: process.env.REACT_APP_KEYCLOAK_RESOURCE,
                        grant_type: "refresh_token",
                        refresh_token: refreshToken,
                    }),
                    {
                        headers: {
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }
                );

                if (response.data) {
                    localStorage.setItem("talentwunder.token", response.data.access_token);
                    localStorage.setItem("talentwunder.refresh.token", response.data.refresh_token);

                    axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.access_token}`;
                    return axiosInstance(originalRequest);
                }
            } catch (refreshError) {
                localStorage.removeItem("talentwunder.token");
                localStorage.removeItem("talentwunder.refresh.token");
                window.location.href = '/login';
                // Optionally redirect to login or handle refresh token failure
                // return Promise.reject(refreshError);
            }
        } else if (status >= 500 && status <= 599) {
            error.response.data = {
                error: {
                    message: "Internal server error. Please contact your administrator!",
                },
            };
        } else if (status === 429) {
            const notifications = store.getState().globalNotifications.notifications;
            if (!notifications.some((notif) => notif.source === notificationSource)) {
                store.dispatch(
                    addMessage({
                        type: "error",
                        title: "Too many requests :(",
                        message: "You are sending too many requests, try again in a bit.",
                        visibilityTime: 5000,
                        source: notificationSource,
                    }),
                );
            }
        }

        return Promise.reject(error);
    }
);

export default axiosInstance;
