import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getUserProfileApi,
    updateUserProfileApi,
    getUserRewardsApi,
    deleteProfilePictureApi,
} from "../../services/api/User/UserService";
import { addMessage } from "../../slices/Notification/GlobalNotificationSlice";
// import { useTranslation } from 'react-i18next';

export const getUserProfile = createAsyncThunk(
    "user/getUserProfile",
    async () => {
        try {
            const { data } = await getUserProfileApi();
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const updateUserProfile = createAsyncThunk(
    "user/updateUserProfile",
    async (payload, { dispatch }) => {
        try {        
            // const { t } = useTranslation();
    
            const { data } = await updateUserProfileApi(payload);

            dispatch(
                addMessage({
                    type: "success",
                    // title: t("toast.user.title2"),
                    message: "User is updated successfully",
                    // message: t("toast.user.message"),
                    visibilityTime: 5000,
                })
            );
            
            return data;
        } catch (error) {
            dispatch(
                addMessage({
                    type: "error",
                    title: "User is not updated successfully",
                    message: error.response.data.message,
                    visibilityTime: 5000,
                })
            );

            return error.response.data;
        }
    }
);

export const deleteProfilePicture = createAsyncThunk(
    "user/deleteProfilePicture",
    async () => {
        try {
            const { data } = await deleteProfilePictureApi();
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const getUserRewards = createAsyncThunk(
    "user/getUserRewards",
    async () => {
        try {
            const { data } = await getUserRewardsApi();
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);
