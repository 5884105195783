import { createSlice } from "@reduxjs/toolkit";
import { getOrganizationSettings } from "../../actions/OrganizationSettings/OrganizationSettingsAction";

const SLICE_NAME = "organizationSettings";

const initialState = {
    loading: true,
    organizationSettings: [],
    organizationSettingsError: false,
};

export const organizationSettingsSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getOrganizationSettings.pending, (state) => {
                state.loading = true;
                state.organizationSettingsError = false;
            })
            .addCase(getOrganizationSettings.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.organizationSettings = payload;
            })
            .addCase(getOrganizationSettings.rejected, (state) => {
                state.loading = false;
                state.organizationSettingsError = true;
            })
    },
});

const getOrganizationSettingsState = (state) => state[SLICE_NAME];

export const organizationSettingsSelectors = {
    loading: (rootState) => {
        const organizationSettingsState = getOrganizationSettingsState(rootState);
        return organizationSettingsState.loading;
    },
    organizationSettings: (rootState) => {
        const organizationSettingsState = getOrganizationSettingsState(rootState);
        return organizationSettingsState?.organizationSettings ?? [];
    },
    organizationSettingsError: (rootState) => {
        const organizationSettingsState = getOrganizationSettingsState(rootState);
        return organizationSettingsState.organizationSettingsError;
    },
};
