import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { userSlice } from "../../../slices/User/UserSlice";
import { globalNotificationSlice } from "../../../slices/Notification/GlobalNotificationSlice";
import { departmentSlice } from "../../../slices/Department/DepartmentSlice";
import { jobSlice } from "../../../slices/Job/JobSlice";
import { referralSlice } from "../../../slices/Referral/ReferralSlice";
import { notificationSlice } from "../../../slices/Notification/NotificationSlice";
import { organizationSettingsSlice } from "../../../slices/OrganizationSettings/OrganizationSettingsSlice";
import { sectionSlice } from "../../../slices/Section/SectionSlice";

const combinedReducer = combineReducers({
    user: userSlice.reducer,
    globalNotifications: globalNotificationSlice.reducer,
    department: departmentSlice.reducer,
    job: jobSlice.reducer,
    referral: referralSlice.reducer,
    notification: notificationSlice.reducer,
    organizationSettings: organizationSettingsSlice.reducer,
    sections: sectionSlice.reducer, 
});

const rootReducer = (state, action) => {
    if (action.type === "logout/fulfilled") {
        // eslint-disable-next-line no-param-reassign
        state = undefined;
    }
    return combinedReducer(state, action);
};

export const store = configureStore({
    reducer: rootReducer,
});
