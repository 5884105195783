import { createAsyncThunk } from "@reduxjs/toolkit";
import { getJobPositionsApi, getSingleJobPositionApi } from "../../services/api/Job/JobService";

export const getJobPositions = createAsyncThunk("job/getJobPositions", async () => {
    try {
        const { data } = await getJobPositionsApi();
        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const getSingleJobPosition = createAsyncThunk("job/getSingleJobPosition", async ({id}) => {
    try {
        const { data } = await getSingleJobPositionApi(id);
        return data;
    } catch (error) {
        return error.response.data;
    }
});
