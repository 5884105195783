import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { extractURLSegment } from "../../utils/helpers";
import { getOrganizationSettings } from "../OrganizationSettings/OrganizationSettingsAction";

const serverUrl = process.env.REACT_APP_KEYCLOAK_URL;
const realm = process.env.REACT_APP_KEYCLOAK_REALM;
const clientId = process.env.REACT_APP_KEYCLOAK_RESOURCE;

if (!serverUrl || !realm || !clientId) {
    throw new Error('Missing required environment variables');
}

export const logIn = createAsyncThunk(
    "login",
    async (payload, { rejectWithValue }) => {
        try {
            const [username, password] = payload;

            const response = await axios.post(
                `${serverUrl}/realms/${realm}/protocol/openid-connect/token`,
                new URLSearchParams({
                    username,
                    password,
                    grant_type: "password",
                    client_id: process.env.REACT_APP_KEYCLOAK_RESOURCE,
                })
            );

            return response.data;
        } catch (err) {
            if (!err.response) {
                throw err;
            }
            return rejectWithValue(err.response.data);
        }
    }
);

export const logOut = createAsyncThunk("logout", async (refreshToken, { dispatch}) => {    
    const params = new URLSearchParams();
    params.append("refresh_token", refreshToken);
    params.append("client_id", clientId);

    const prefix = extractURLSegment(window.location.href);

    try {
        await axios.post(
            `${serverUrl}/realms/${realm}/protocol/openid-connect/logout`,
            params,
            {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
            }
        );
        dispatch(getOrganizationSettings(prefix));
    } catch (err) {
        throw err;
    }
});
