import { createSlice } from "@reduxjs/toolkit";
import { getSections } from "../../actions/Section/SectionAction";

const SLICE_NAME = "sections";

const initialState = {
    loading: true,
    sections: [],
    sectionError: false
};

export const sectionSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getSections.pending, (state) => {
                state.loading = true;
                state.sectionError = false;
            })
            .addCase(getSections.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.sections = payload;
            })
            .addCase(getSections.rejected, (state) => {
                state.loading = false;
                state.sectionError = true;
            });
    },
});

const getSectionState = (state) => state[SLICE_NAME];

export const sectionSelectors = {
    loading: (rootState) => {
        const sectionState = getSectionState(rootState);
        return sectionState.loading;
    },
    sections: (rootState) => {
        const sectionState = getSectionState(rootState);
        return sectionState.sections ?? [];
    },
    sectionError: (rootState) => {
        const sectionState = getSectionState(rootState);
        return sectionState.sectionError;
    },
};
