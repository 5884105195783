const version = require("../../package.json").version;

export const getEnvironment = () => {
    return process.env.REACT_APP_ENV;
};

export const getVersion = () => {
    return version;
};

export const isLocalEnvironment = () => {
    return process.env.REACT_APP_ENV === 'local';
};

export const isDevelopmentEnvironment = () => {
    return process.env.REACT_APP_ENV === 'local' || process.env.REACT_APP_ENV === 'test';
};

export const isProductionEnvironment = () => {
    return process.env.REACT_APP_ENV === 'prod';
};
