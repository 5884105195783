import { createAsyncThunk } from "@reduxjs/toolkit";
import {
    getNotificationsApi,
    updateNotificationApi,
    updateNotificationsApi,
    deleteNotificationApi,
} from "../../services/api/Notification/NotificationService";

export const getNotifications = createAsyncThunk(
    "notification/getNotifications",
    async () => {
        try {
            const { data } = await getNotificationsApi();
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const updateNotification = createAsyncThunk(
    "notification/updateNotification",
    async (id, {dispatch}) => {
        try {
            const { data } = await updateNotificationApi(id);
            dispatch(getNotifications());

            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const deleteNotification = createAsyncThunk(
    "notification/deleteNotification",
    async (id, { dispatch }) => {
        try {
            const { data } = await deleteNotificationApi(id);
            dispatch(getNotifications());
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);

export const updateNotifications = createAsyncThunk(
    "notification/updateNotifications",
    async (payload, {dispatch}) => {
        try {
            const { data } = await updateNotificationsApi();
            dispatch(getNotifications());
            return data;
        } catch (error) {
            return error.response.data;
        }
    }
);
