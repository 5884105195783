import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getNotifications } from "./actions/Notification/NotificationAction";
import { getUserRewards } from "./actions/User/UserAction";
import { userSelectors } from "./slices/User/UserSlice";

const NotificationProvider = ({ children }) => {
    const refreshInterval = 300000;

    const dispatch = useDispatch();
    const { id } = useSelector(userSelectors.userProfile);
    const token = localStorage.getItem("talentwunder.token");

    /* eslint-disable */
    useEffect(() => {
        const action = () => {
            if (!id && token !== null) {
                dispatch(getNotifications());
                dispatch(getUserRewards());
            }
        };

        const intervalId = setInterval(action, refreshInterval);

        return () => clearInterval(intervalId);
    }, []);
    /* eslint-enable */

    return <>{children}</>;
};

export default NotificationProvider;
