import { createSlice } from "@reduxjs/toolkit";
import { getDepartments } from "../../actions/Department/DepartmentAction";

const SLICE_NAME = "department";

const initialState = {
    loading: false,
    departments: [],
    departmentError: false,
};

export const departmentSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getDepartments.pending, (state) => {
                state.loading = true;
                state.departmentError = false;
            })
            .addCase(getDepartments.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.departments = payload;
            })
            .addCase(getDepartments.rejected, (state) => {
                state.loading = false;
                state.departmentError = true;
            });
    },
});

const getDepartmentState = (state) => state[SLICE_NAME];

export const departmentSelectors = {
    loading: (rootState) => {
        const departmentState = getDepartmentState(rootState);
        return departmentState.loading;
    },
    departments: (rootState) => {
        const departmentState = getDepartmentState(rootState);
        return departmentState.departments;
    },
    departmentError: (rootState) => {
        const departmentState = getDepartmentState(rootState);
        return departmentState.departmentError;
    },
};
