import { createAsyncThunk } from "@reduxjs/toolkit";
import { getReferralsApi, createReferralApi } from "../../services/api/Referral/ReferralService";
import { addMessage } from "../../slices/Notification/GlobalNotificationSlice";
import { setModal, setDrawer } from "../../slices/Referral/ReferralSlice";

export const getReferrals = createAsyncThunk("referral/getReferrals", async () => {
    try {
        const { data } = await getReferralsApi();

        return data;
    } catch (error) {
        return error.response.data;
    }
});

export const createReferral = createAsyncThunk("referral/createReferral", async (payload, { dispatch}) => {
    try {
        const { data } = await createReferralApi(payload);
        dispatch(getReferrals());
        dispatch(setModal(true));

        dispatch(setDrawer({
            type: "",
            isOpen: false,
        }));
        return data;
    } catch (error) {
        dispatch(
            addMessage({
                type: "error",
                title: "Error creating referral",
                message: error.response?.data?.message || error.message,
                visibilityTime: 5000,
            }),
        );

        throw(getReferrals());
    }
});
