import { axiosInstance } from "../../../config/api/Axios";

export const getUserProfileApi = async () => axiosInstance.get("/users/profile");

export const updateUserProfileApi = (payload) => {
    localStorage.setItem("talentwunder.firstName", payload.firstName);
    localStorage.setItem("talentwunder.lastName", payload.lastName);
    localStorage.setItem("talentwunder.emailNotifications", payload.emailNotifications);

    if (payload.profilePicture) {
        localStorage.setItem("talentwunder.profilePicture", payload.profilePicture);
    }

    return axiosInstance.patch("/users/profile", payload);

};

export const deleteProfilePictureApi = () => {
    localStorage.removeItem("talentwunder.profilePicture");
    return axiosInstance.delete("/users/profile/picture");
};

export const getUserRewardsApi = async () => axiosInstance.get("/rewards");
