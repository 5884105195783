import React, { lazy } from "react";

import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { store } from "./config/core/Store";
import "./index.css";
import i18n from "./i18n";
import DataProvider from "./DataProvider";
import { I18nextProvider } from "react-i18next";

const App = lazy(() => import("./App"));

const rootElement = document.getElementById("root");

const root = createRoot(rootElement);

root.render(
    <Provider store={store}>
        <I18nextProvider i18n={i18n}>
            <DataProvider>
                <App />
            </DataProvider>
        </I18nextProvider>
    </Provider>
);
