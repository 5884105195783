import { createAsyncThunk } from "@reduxjs/toolkit";
import { getDepartmentsApi } from "../../services/api/Department/DepartmentService";

export const getDepartments = createAsyncThunk("department/getDepartments", async () => {
    try {
        const { data } = await getDepartmentsApi();
        return data;
    } catch (error) {
        return error.response.data;
    }
});
