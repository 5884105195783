import { axiosInstance } from "../../../config/api/Axios";

export const getReferralsApi = async () => axiosInstance.get("/referrals");

export const createReferralApi = (payload) => {
    const config = {
        headers: {
            "Content-Type": "multipart/form-data",
        },
    };
    return axiosInstance.post("/referrals", payload, config);
};
