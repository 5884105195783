import { format, formatDistanceToNow } from "date-fns";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import queryString from "query-string";

export const formatDate = (dateString, formatString = "dd-MMM-yyyy") => {
    const date = new Date(dateString);
    return format(date, formatString);
};

export const formatTimeElapsed = (dateString) => {
    const date = new Date(dateString);
    return formatDistanceToNow(date);
};

export default function useQueryParams() {
    const { search } = useLocation();
    const [queryParams, setQueryParams] = useState(queryString.parse(search));

    useEffect(() => {
        setQueryParams(queryString.parse(search));
    }, [search]);

    return queryParams;
}

export const hexToRGBA = (hex, opacity) => {
    const r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

export const extractURLSegment = (url) => {
    const parts = url.replace(/(https?:\/\/|www\.)/, '').split('.');

    return parts[0];
};
