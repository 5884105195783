import { axiosInstance } from "../../../config/api/Axios";

export const getNotificationsApi = async () => axiosInstance.get("/notifications");

export const updateNotificationApi = async (id) => axiosInstance.post(`/notifications/mark-as-read`, {
    ids: [id]
});

export const deleteNotificationApi = async (id) => axiosInstance.delete(`/notifications/${id}`);

export const updateNotificationsApi = async () => axiosInstance.post(`/notifications/mark-as-read`, {
    ids: []
});
