import { createSlice } from "@reduxjs/toolkit";
import { getJobPositions, getSingleJobPosition } from "../../actions/Job/JobAction";
import jobFields from "../../assets/jobFields.json";

const SLICE_NAME = "job";

const initialState = {
    loading: true,
    jobPositions: [],
    singleJobPosition: {},
    jobError: false,
    jobTitle: "",
    lang: localStorage.getItem("talentwunder.application.language") === "en-US" ? "en" : "de",
};

export const jobSlice = createSlice({
    name: SLICE_NAME,
    initialState,
    extraReducers: (builder) => {
        builder
            .addCase(getJobPositions.pending, (state) => {
                state.loading = true;
                state.jobError = false;
            })
            .addCase(getJobPositions.fulfilled, (state, { payload }) => {
                const { contractType, jobType, fieldOfActivity } = jobFields || {};
                /* eslint-disable */
                const updatedPayload = payload.map(item => {
                    return {
                        ...item,
                        type: item.type != null ? jobType.find(jobTypeitem => jobTypeitem.id == item.type)[state.lang]  ?? "" : "",
                        contractType: item.contractType != null ? contractType.find(contractTypeItem => contractTypeItem.id == item.contractType)[state.lang] ?? " " : "",
                        field: item.field != null ? fieldOfActivity.find(fieldOfActivityItem => fieldOfActivityItem.id == item.field)[state.lang] ?? "" : "",
                    };
                });
                /* eslint-enable */

                state.loading = false;
                state.jobPositions = updatedPayload;
            })
            .addCase(getJobPositions.rejected, (state) => {
                state.loading = false;
                state.jobError = true;
            })
            .addCase(getSingleJobPosition.pending, (state) => {
                state.loading = true;
                state.jobError = false;
            })
            .addCase(getSingleJobPosition.fulfilled, (state, { payload }) => {
                state.loading = true;
                state.jobTitle = payload.title;
                const { contractType, jobType, fieldOfActivity } = jobFields || {};
                /* eslint-disable */
                const contractTypeData = payload.contractType != null ? contractType.find(item => item.id == payload.contractType) : "";
                const jobTypeData = payload.type != null ? jobType.find(item => item.id == payload.type) : "";
                const fieldOfActivityData = payload.field != null ? fieldOfActivity.find(item => item.id == payload.field) : "";
                /* eslint-enable */
                payload.type = jobTypeData[state.lang] ?? "";
                payload.contractType = contractTypeData[state.lang] ?? "";
                payload.field = fieldOfActivityData[state.lang] ?? "";

                state.loading = false;
                state.singleJobPosition = payload;
            })
            .addCase(getSingleJobPosition.rejected, (state) => {
                state.loading = false;
                state.jobError = true;
            });
    },
    reducers: {
        setJobTitle: (state, action) => {
            state.jobTitle = action.payload;
        }
    }
});

const getJobState = (state) => state[SLICE_NAME];

export const jobSelectors = {
    loading: (rootState) => {
        const jobState = getJobState(rootState);
        return jobState.loading;
    },
    jobPositions: (rootState) => {
        const jobState = getJobState(rootState);
        return jobState.jobPositions ?? [];
    },
    singleJobPosition: (rootState) => {
        const jobState = getJobState(rootState);
        return jobState.singleJobPosition ?? {};
    },
    jobError: (rootState) => {
        const jobState = getJobState(rootState);
        return jobState.jobError;
    },
};

export const { setJobTitle } = jobSlice.actions;